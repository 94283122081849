<template>
  <div class="columns is-multiline has-background-bluedark2">

    <div class="column is-4 has-background-bluedark2">
      <b-table blueDark2 :data="data" :columns="columns" :selected.sync="selected" focusable hoverable></b-table>
    </div>

    <div class="column is-8 has-background-bluedark">

      <b-tabs type="is-toggle" expanded>

        <b-tab-item label="Cadastro" icon="id-badge" icon-pack="fas">
          <div class="columns is-multiline">
            <div class="column is-full">
              <b-button type="is-success" @click="save()">Salvar</b-button>
              <b-button type="is-warning" @click="reset()">Limpar</b-button>
              <b-button v-if="form.id" type="is-danger" @click="del()">Excluir</b-button>
            </div>
            <div class="column has-background-bluedark2">
              
              <b-tabs type="is-boxed" size="is-small" :vertical="$mq !== 'sm'" :expanded="$mq == 'sm'">

                <b-tab-item label="Dados" icon="id-card" icon-pack="far">
                  <div class="columns is-multiline">
                    <div class="column is-10">
                      <small>* - campos obrigatórios, os outros podem permanecer em branco</small>
                    </div>
                    <div class="column is-2">
                      <img :src="form.url_foto" :alt="form.nome_midia" style="position: absolute; float: right; height: 70px"/>
                    </div>
                    <div class="column is-half">
                      <b-field label="Nome *">
                        <b-input v-model="form.nome"></b-input>
                      </b-field>
                    </div>
                    <div class="column is-half">
                      <b-field label="Nome de Mídia *">
                        <b-input v-model="form.nome_midia"></b-input>
                      </b-field>
                    </div>
                    <div class="column is-full">
                      <b-field label="URL - Foto" style="display: block">
                        <small>clique no icone para abrir o site para realizar upload da imagem</small>
                        <b-input
                          type="search"
                          v-model="form.url_foto"
                          icon="share"
                          icon-clickable
                          @icon-click="handlerLink()">
                        </b-input>
                      </b-field>
                    </div>
                  </div>
                </b-tab-item>

                <b-tab-item label="Contatos" icon="envelope" icon-pack="far">
                  <b-field label="Contatos">
                    <template slot="label">
                      <p>Contatos</p>
                      <small>Selecione o ícone desejado para a inclusão do contato</small>
                    </template>
                    <div class="columns is-mobile" :class="{'is-gapless': $mq == 'sm'}">
                      <div class="column" v-for="(contactType) of contactsType" :key="contactType.slug" @click="addContact(contactType)">
                        <b-tooltip :label="contactType.descricao" type="is-primary" position="is-bottom" animated>
                          <b-icon :pack="(contactType.slug=='envelope')?'fas':'fab'" :icon="contactType.slug" size="is-medium" type="is-primary"></b-icon>
                        </b-tooltip>
                      </div>
                    </div>
                  </b-field>

                  <b-field v-for="(contato) of form.contatos" :key="contato.id_contato" :label="contato.tipo_descricao" style="display: block;">
                    <b-input :icon-pack="(contato.slug=='envelope')?'fas':'fab'" :icon="contato.slug" v-model="contato.descricao"></b-input>
                    <b-input icon-pack="fas" icon="link" v-model="contato.link"></b-input>
                  </b-field>
                </b-tab-item>

                <b-tab-item label="Configurações" icon="cog" icon-pack="fas">

                  <div class="columns is-multiline">
                    <div class="column is-6">
                      <b-field label="% Escala10" label-position="on-border">
                        <b-input type="number" step="0.0001" v-model="form.percent_escala" :disabled="!userInfo.isAdmin"></b-input>
                      </b-field>
                    </div>

                    <div class="column is-6">
                      <b-field label="% Influenciador" label-position="on-border">
                        <b-input type="number" step="0.0001" v-model="form.percent_sala" :disabled="!userInfo.isAdmin"></b-input>
                      </b-field>
                    </div>

                    <div class="column is-12">
                      <b-field 
                        v-for="(config) of form.configs"
                        :key="config.nome"
                        :label="config.titulo ? config.titulo : config.nome" 
                        style="display: block"
                      >
                        <div class="columns is-gapless is-vcentered" v-if="config.tipo == 'file'">
                          <div class="column">
                            <small>clique no icone para abrir o site para realizar upload da imagem</small>
                          </div>
                          <div class="column is-narrow">
                            <img :src="config.valor" :alt="config.nome" style="height: 35px"/>
                          </div>
                        </div>
                        <b-input
                          v-if="config.tipo == 'file'"
                          type="search"
                          v-model="config.valor"
                          icon="share"
                          icon-clickable
                          @icon-click="handlerLink()"
                        >
                        </b-input>

                        <b-input
                          v-if="config.tipo == 'text'"
                          type="text"
                          v-model="config.valor"
                        >
                        </b-input>

                        <b-select 
                          v-if="config.tipo == 'select'"
                          v-model="config.valor"
                        >
                          <option
                            v-for="opc in config.opcoes"
                            :key="config.nome + opc.valor"
                            :value="opc.valor"
                          >
                            {{ opc.nome }}
                          </option>
                        </b-select>

                        <template 
                          v-if="config.tipo == 'radio'"
                        >
                          <b-radio 
                            v-for="opc in config.opcoes"
                            :key="config.nome + opc.valor"
                            v-model="config.valor"
                            :name="config.nome"
                            :native-value="opc.valor"
                          >
                            <span>{{ opc.nome }}</span>
                          </b-radio>
                        </template>

                        <template 
                          v-if="config.tipo == 'checkbox'"
                        >
                          <b-checkbox 
                            v-for="opc in config.opcoes"
                            :key="config.nome + opc.valor"
                            v-model="config.valor"
                            :name="config.nome"
                            :native-value="opc.valor"
                          >
                            <span>{{ opc.nome }}</span>
                          </b-checkbox>
                        </template>

                        <mc-wysiwyg 
                          v-if="config.tipo == 'memo'"
                          v-model="config.valor"
                        ></mc-wysiwyg>

                      </b-field>
                    </div>
                  </div>
                </b-tab-item>

              </b-tabs>
            </div>
          </div>
        </b-tab-item>

        <b-tab-item label="Vendas" icon="money-bill-wave" icon-pack="fas">

          <div class="columns is-multiline">
            
            <div class="column is-full has-text-centered">
              <b-tag type="is-primary" size="is-large">
                <p v-if="renderSales">{{sales.length}} Assinatura(s) = R$ {{this.totalSales.toFixed(2)}}</p>
              </b-tag>
            </div>

            <div class="column is-full">
              <b-table
                blueDark2
                v-if="renderSales"
                :data="sales"  
                :mobile-cards="true"
                default-sort="data_hora"
                default-sort-direction="desc"
                :paginated="true"
                :per-page="10">

                <template slot-scope="props" slot="header">
                  <div v-if="props.column.sortable" class="label-table centralize-row">
                    <span v-if="props.column.sortable">
                      <b-icon icon="sort" pack="fas" size="is-small"></b-icon>
                    </span>
                    <p>{{ props.column.label }}</p>
                  </div>
                  <div v-else class="label-table centralize-row">
                    <p>{{ props.column.label }}</p>
                  </div>
                </template>

                <template slot-scope="props">

                  <b-table-column field="saleTime" label="Data/Hora" sortable searchable>
                    {{ props.row.saleTime }}
                  </b-table-column>

                  <b-table-column field="ds_periodo" label="Plano" sortable searchable>
                    {{ props.row.ds_periodo }} {{ (props.row.cupom !== "") ? ' - cupom: '+props.row.cupom : '' }}
                  </b-table-column>

                  <b-table-column field="integracao" label="FormaPagto" sortable searchable>
                    {{ props.row.integracao }} {{ (props.row.integracao !== props.row.tipo) ? props.row.tipo : '' }}
                  </b-table-column>

                  <b-table-column field="reference" label="Referência" sortable searchable>
                    {{ props.row.reference }}
                  </b-table-column>

                  <b-table-column field="valor" label="Valor" right numeric>
                    {{ props.row.valor }}
                  </b-table-column>

                  <b-table-column field="name_pssa" label="Cliente" sortable searchable>
                    {{ props.row.name_pssa }} 
                    <span v-if="props.row.cpf_pssa !== ''"><br>{{props.row.cpf_pssa}}</span>
                    <span v-if="props.row.phone_pssa !== ''"><br>{{props.row.phone_pssa}}</span>
                    <span v-if="props.row.mail_pssa !== ''"><br>{{props.row.mail_pssa}}</span>
                  </b-table-column>

                </template>

                <template slot="empty">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered">
                      <p>
                        <b-icon
                          icon="emoticon-sad"
                          size="is-large">
                        </b-icon>
                      </p>
                      <p>Nenhum Registro.</p>
                    </div>
                  </section>
                </template>
              </b-table>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Moment from 'moment'

export default {
  name: 'Influencers',
  data() {
    return {
      teste: "",
      form: {
        "nome": "",
        "nome_midia": "",
        "url_foto": null,
        "percent_escala": null,
        "percent_sala": null,
        "contatos": [],
        "configs": []
      },
      data: [],
      contactsType: [],
      columns: [
        {
            field: 'id',
            label: 'ID',
            width: '40',
            numeric: true
        },
        {
            field: 'nome_midia',
            label: 'Nome Mídia',
        },
        {
            field: 'nome',
            label: 'Nome',
        },
      ],
      selected: {},
      renderSales: false,
      sales: [],
      totalSales: 0
    }
  },
  computed: {
    ...mapGetters(['userId','userInfo']),
  },
  watch: {
    selected: async function (val) {
      
      if (this.selected.id){

        let loader = this.$loading.show()

        this.form = this.selected

        this.form.configs.map(c => {
          if ('select,radio,checkbox'.indexOf(c.tipo) !== -1){
            if (c.tipo == "checkbox"){
              c.valor = (c.valor && c.valor !== "") ? JSON.parse(c.valor) : []
            }
            c.opcoes = []
            let opcoes = c.valor_padrao.split(',')
            opcoes.map(o => {
              let opc = o.split('=')
              if (opc.length > 1){
                c.opcoes.push({valor: opc[0].trim(), nome: opc[1].trim()})
              } else {
                c.opcoes.push({valor: opc[0].trim(), nome: opc[0].trim()})
              }
            })
          }
        })

        this.renderSales = false
        this.sales = await this.getInfluencersSales(this.selected.id)
        this.totalSales = 0

        this.sales = this.sales.map((el,index) => {
          let saleTime = Moment(new Date(el.data_hora))
          saleTime = saleTime.format('DD/MM/YYYY - HH:mm:ss')
          this.totalSales += el.valor
          return {...el, saleTime}
        })
        
        this.renderSales = true
        loader.hide()
      }
    }
  },
  async created() {
    let loader = this.$loading.show()
    this.data = (await this.getInfluencers()).data
    this.contactsType = (await this.getContactsType()).data
    loader.hide()
  },
  methods: {
    ...mapActions(["createInfluencer", "deleteInfluencerById", "getInfluencers", "getInfluencersSales", "getContactsType"]),
    formatter (form) {
      
      form.configs.map(c => {
        if (typeof c.valor == "object"){
          c.valor = JSON.stringify(c.valor)
        }
      })

      let contatos = form.contatos.filter(el => el.descricao != '')
      let configs = form.configs.filter(el => el.valor != '')

      return {
        id: (form.id) ? form.id : 0,
        nome: form.nome,
        nome_midia: form.nome_midia,
        percent_escala: form.percent_escala,
        percent_sala: form.percent_sala,
        url_foto: form.url_foto,
        contatos,
        configs
       }
    },
    async save() {

      let loader = this.$loading.show()

      try {
        let params = this.formatter(JSON.parse(JSON.stringify(this.form)))
        let data = await this.createInfluencer(params)

        if (data.data.status == 'success'){
          this.$toasted.success('Influenciador registrado com sucesso!', {
            duration: 3000,
            position: 'top-center'
          })
          this.data = (await this.getInfluencers()).data
          this.reset()
        } else {
          this.$toasted.error('Ocorreu um problema na atualização! Favor informar ao administrador', {
            duration: 3000,
            position: 'top-center'
          })
        }

      } catch (err) {
        console.log(err)
        this.$toasted.error('Ocorreu um problema ao atualizar o Influenciador!', {
          duration: 3000,
          position: 'top-center'
        })
      }

      loader.hide()
    },
    async reset(){
      this.form = {
        "nome": "",
        "nome_midia": "",
        "percent_escala": null,
        "percent_sala": null,
        "url_foto": null,
        "contatos": []
      }
      this.selected = {}
      this.renderSales = false
      this.sales = []
      this.totalSales = 0
    },
    async del(){
      if (this.form.id){
        try {

          let data = await this.deleteInfluencerById(this.form.id)

          if (data.data){
            this.$toasted.success('Influenciador excluído com sucesso!', {
              duration: 3000,
              position: 'top-center'
            })

            this.data = (await this.getInfluencers()).data
            this.reset()
          } else {
            this.$toasted.error('Exclusão não permitida devido a registros relacionados!', {
              duration: 3000,
              position: 'top-center'
            })
          }

        } catch (err) {
          console.log(err)
          this.$toasted.error('Ocorreu um problema ao excluir o Influenciador!', {
            duration: 3000,
            position: 'top-center'
          })
        }
      }
    },
    addContact(contactType) {
      if (contactType){
        let existContact = false
        for (const contact of this.form.contatos) {
          if (contact.tipo_contato == contactType.id_tipo_contato){
            existContact = true
          }
        }
        if (!existContact){
          this.form.contatos.push({
            "id_contato": 0,
            "tipo_contato": contactType.id_tipo_contato,
            "tipo_descricao": contactType.descricao,
            "slug": contactType.slug,
            "descricao": "",
            "link": null
          })
        }
      }
    },
    handlerLink() {
      window.open('https://ibb.co/album/iAsgBF?sort=date_asc&page=1&params_hidden%5Blist%5D=images&params_hidden%5Bfrom%5D=album&params_hidden%5Balbumid%5D=iAsgBF', '_blank');
    }
  }
}
</script>

<style scoped>
.sales-table {
  width: 100%;
  height: auto !important;
  overflow-y: auto;
  background-color: #4d4d4d;
  border-radius: 7px;
  padding: 5px;
}
</style>
